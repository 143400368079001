import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/layout";
import "./index.scss";
import Pattern from "../images/svg/panel_long_1.svg";
import { graphql } from "gatsby";
import { ChapterTitle } from "../components/Sections/ChapterTitle/chapterTitle"
import { getResource } from "../utils/getResource"
import { SubsectionTitle } from "../components/Sections/SubsectionTitle/subsectionTitle"
import { Section3 } from "../components/Sections/Section3/section3"
import { Section1 } from "../components/Sections/Section1/section1"
import { Section2 } from "../components/Sections/Section2/section2"
import { Section5 } from "../components/Sections/Section5/section5"
import { Section4 } from "../components/Sections/Section4/section4"
import { PageNavigation } from "../components/PageNavigation/pageNavigation"
import { Section3a } from "../components/Sections/Section3/section3a"

const Values = ({ data, location }) => {
  const pageResources = data.allMarkdownRemark.edges;
  const valuesRefs = useRef([])
  const [pageRefs, setPageRefs] = useState([])

  useEffect(() => {
    const pageRefsArr = valuesRefs.current.map(ref => ({ id: ref.id, offset: ref.offsetTop, height: ref.offsetHeight }))

    setPageRefs(pageRefsArr)
  }, [])

  return (
    <Layout location={location} pageRefs={pageRefs} sectionClass="m-section__relative-section m-main__section-values">
      <div className="m-main__content m-main__content-values">
        <div className="m-main__section-content">
          <div className="m-main__section-pattern">
          <Pattern />
        </div>
        <ChapterTitle text={getResource(pageResources, 'wartosci-title').content} bgImage={getResource(pageResources, 'wartosci-title').image} />
        <div ref={el => valuesRefs.current[0] = el} id="exhibition-values-belief">
          <SubsectionTitle text={getResource(pageResources, 'wartosci-subtitle-1').content} />
          <Section3
            imgLeftSrc={getResource(pageResources, 'wartosci-text-33').image}
            imgRightSrc={getResource(pageResources, 'wartosci-text-34').image}
            txtLeft={getResource(pageResources, 'wartosci-text-33').content}
            txtRight={getResource(pageResources, 'wartosci-text-34').content}
            imgLeftCaption={getResource(pageResources, 'wartosci-text-33').imageContent}
            imgRightCaption={getResource(pageResources, 'wartosci-text-34').imageContent}
          />
          <Section2 option={'textOnly'} text={getResource(pageResources, 'wartosci-text-35').content} bgImg={getResource(pageResources, 'wartosci-text-35').bgImage} />
          <Section3
            txtLeft={getResource(pageResources, 'wartosci-text-36').content}
            imgLeftSrc={getResource(pageResources, 'wartosci-text-36').image}
            txtRight={getResource(pageResources, 'wartosci-text-37').content}
            imgRightSrc={getResource(pageResources, 'wartosci-text-37').image}
            imgLeftCaption={getResource(pageResources, 'wartosci-text-36').imageContent}
            imgRightCaption={getResource(pageResources, 'wartosci-text-37').imageContent}
            customSectionClass={'m-section__values-img-37'}
          />
          <Section2 option={'twoColumns'} text={getResource(pageResources, 'wartosci-text-38').content} imgSrc={getResource(pageResources, 'wartosci-text-38').image} bgImg={getResource(pageResources, 'wartosci-text-38').bgImage} caption={getResource(pageResources, 'wartosci-text-38').imageContent} isReversed imgFull />
          <Section1 text={getResource(pageResources, 'wartosci-text-39').content} imgSrc={getResource(pageResources, 'wartosci-text-39').image} centered imgCaption={getResource(pageResources, 'wartosci-text-39').imageContent} />
          <Section5 customSectionClass="m-section__values-parallax-39" imgSrc={getResource(pageResources, 'wartosci-img-1').image} caption={getResource(pageResources, 'wartosci-img-1').imageContent} />
        </div>
        <div ref={el => valuesRefs.current[1] = el} id="exhibition-values-labour" className={'section m-section__values-labour'}>
          <SubsectionTitle textOnly text={getResource(pageResources, 'wartosci-subtitle-2').content} />
          <Section4 bgImgSrc={getResource(pageResources, 'wartosci-text-41').bgImage} imgSrc={getResource(pageResources, 'wartosci-text-41').image} caption={getResource(pageResources, 'wartosci-text-41').imageContent} text={getResource(pageResources, 'wartosci-text-41').content} reverse extended />
          <Section5 customSectionClass="m-section__values-parallax-41" imgSrc={getResource(pageResources, 'wartosci-img-2').image} caption={getResource(pageResources, 'wartosci-img-2').imageContent} />
          <Section3
            imgLeftSrc={getResource(pageResources, 'wartosci-text-42').image}
            imgRightSrc={getResource(pageResources, 'wartosci-text-43').image}
            txtLeft={getResource(pageResources, 'wartosci-text-42').content}
            txtRight={getResource(pageResources, 'wartosci-text-43').content}
            imgLeftCaption={getResource(pageResources, 'wartosci-text-42').imageContent}
            imgRightCaption={getResource(pageResources, 'wartosci-text-43').imageContent}
            imgRightFull
            imgFull
          />
          <Section4 extended bgImgSrc={getResource(pageResources, 'wartosci-text-44').bgImage} text={getResource(pageResources, 'wartosci-text-44').content} caption={getResource(pageResources, 'wartosci-text-44').imageContent} imgSrc={getResource(pageResources, 'wartosci-text-44').image} reverse />
          <Section1 text={getResource(pageResources, 'wartosci-text-45').content} imgCaption={getResource(pageResources, 'wartosci-text-45').imageContent} imgSrc={getResource(pageResources, 'wartosci-text-45').image} centered />
        </div>
        <div ref={el => valuesRefs.current[2] = el} id="exhibition-values-solidarity">
          <SubsectionTitle text={getResource(pageResources, 'wartosci-subtitle-3').content} />
          <Section2 option={'textOnly'} bgImg={getResource(pageResources, 'wartosci-text-47').bgImage} text={getResource(pageResources, 'wartosci-text-47').content} isReversed starReversed imgFull />
          <Section3a
            imgLeftSrc={getResource(pageResources, 'wartosci-text-48').image}
            imgRightSrc={getResource(pageResources, 'wartosci-text-49').image}
            txtLeft={getResource(pageResources, 'wartosci-text-48').content}
            txtRight={getResource(pageResources, 'wartosci-text-49').content}
            imgLeftCaption={getResource(pageResources, 'wartosci-text-48').imageContent}
            imgRightCaption={getResource(pageResources, 'wartosci-text-49').imageContent}
            fullWidth
            customClass={'m-section__values-49-img'}
          />
          <Section2 option={'twoColumns'} caption={getResource(pageResources, 'wartosci-text-50').imageContent} text={getResource(pageResources, 'wartosci-text-50').content} bgImg={getResource(pageResources, 'wartosci-text-50').bgImage} imgSrc={getResource(pageResources, 'wartosci-text-50').image} starReversed />
          <Section1 imgCaption={getResource(pageResources, 'wartosci-text-51').imageContent} text={getResource(pageResources, 'wartosci-text-51').content} imgSrc={getResource(pageResources, 'wartosci-text-51').image} isReversed />
          <Section4 text={getResource(pageResources, 'wartosci-text-52').content} imgSrc={getResource(pageResources, 'wartosci-text-52').image} bgImgSrc={getResource(pageResources, 'wartosci-text-52').bgImage} caption={getResource(pageResources, 'wartosci-text-52').imageContent} isReversed centered />
        </div>
        <PageNavigation nextItem={'exhibition-culture'} prevItem={'exhibition-history'} />
      </div>
      </div>
    </Layout>
  )
}

export default Values

export const pageQuery = graphql`
query($language: String) {
  allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "values-item"}, language: { eq: $language }}}
  ) {
    edges {
      node {
        frontmatter {
          title
          language
          order
          content
          image
          bgImage
          imageContent
        }
      }
    }
  }
}`
