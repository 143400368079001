import React from "react";
import Pattern from "../../../images/panel_long_1.png";
import './index.scss';
import { CaptionImg } from "../../CaptionImg/captionImg"

export const Section3a = ({ imgLeftSrc, imgRightSrc, txtLeft, txtRight, isExpanded, customImgStyle, fullWidth, imgLeftCaption, imgRightCaption, customClass }) => {
  return (
    <div className={`m-main__section m-section__3 ${customClass}`}>
      <div className={'m-section__3-bg'} style={{ backgroundImage: `url(${Pattern})` }}></div>
      <div className="m-section__3-content">
        <div className="m-section__3-left">
          <p className="m-main__section-text m-section__3-up">
            {txtLeft}
          </p>
          <CaptionImg imgSrc={imgLeftSrc}
                      customClass={'m-section__3-img'}
                      caption={imgLeftCaption}
                      customImgStyle={customImgStyle}
          />
        </div>
        <div className="m-section__3-right">
          <p className="m-main__section-text m-main__section-text-right">
            {txtRight}
          </p>
          <CaptionImg imgSrc={imgRightSrc}
                      customClass={`m-section__3-img ${!isExpanded ? 'm-section__3-img--smaller' : ''} ${fullWidth ? 'm-section__3-img--full' : ''}` }
                      caption={imgRightCaption}
                      customImgStyle={customImgStyle}
          />
        </div>
      </div>
    </div>
  )
}
